<template>
    <div class="addMachine bg-dark">
        <div class="alert" role="alert" id="snackbar">
          <strong>Error:</strong> All fields are requred!!!
        </div>
        <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong>Machine details has been updated!!!
        </div>
        <div class="form">
        <form class="login-form">
         <div class="form-group">
            <label for="name">Mechine Name:</label>
            <input type="name" class="form-control" placeholder="Enter name" id="machineName" v-model="machineName">
        </div>
         <div class="form-group">
            <label for="position">Position:</label>
            <input type="position" class="form-control" placeholder="Enter position" id="position" v-model="position">
        </div>
         <div class="form-group">
            <label for="position">Type:</label>
            <select class="form-control" v-model="type" id="type">
                <option disabled selected>Choose Type</option>
                <option v-for="type in machinesType" :key="type.type">{{ type }}</option>
            </select>
        </div>
         <div class="form-group">
            <label for="position">Site:</label>            
            <select class="form-control" v-model="site" id="site"> 
                <option disabled selected>Choose Site</option>
                <option v-for="site in sites" :key="site.site">{{ site }}</option>
            </select>
        </div>
         <div class="form-group">
            <label for="ramacDevice">RAMAC Device:</label>
              <input type="text" class="form-control" placeholder="Enter ramacDevice ID" id="ramacDevice" v-model="ramacDevice">
        </div>
      <button type="button" class="btn-sm btn-info" @click="addMechine">Submit</button>
      <!-- <router-link tag="button" to="/" class="btn-sm btn-danger">Cancel</router-link> -->
      <button @click="back()" class="btn-sm btn-danger">Cancel</button>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            machineName: '',
            position: '',
            type: '',
            site: '',
            ramacDevice: '',
            image: '',
            sites: [
               'Site A',
               'Site B'
            ],
            machinesType: [
                'Compression Mould',
                'Injection Mould',
                'Extruder',
                'Corrugator'
            ],
        }
    },

methods: {
     addMechine() {
         if(this.machineName == '' || this.position == '' || this.type == '' || this.site == '' || this.ramacDevice == '') {
            //VALIDATE IF FIELDS ARE EMPTY
            if(this.machineName == '') {
                document.getElementById('machineName').style.border = '2px solid red'
            }
            if(this.position == '') {
                document.getElementById('position').style.border = '2px solid red'
            }
            if(this.type == '') {
                document.getElementById('type').style.border = '2px solid red'
            }
            if(this.site == '') {
                document.getElementById('site').style.border = '2px solid red'
            }
            if(this.ramacDevice == '') {
                document.getElementById('ramacDevice').style.border = '2px solid red'
            }
            //END VALIDATE IF FIELDS ARE EMPTY
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
         } else {
             let formData = new FormData();
            //AUDIT LOG PARAMS
             let fullname_user = localStorage.getItem('fullname') 
             let user_action = 'Add Machine'
             let description = fullname_user +" added new machine " + this.machineName

             formData.append('machineName', this.machineName);
             formData.append('position', this.position);
             formData.append('type', this.type);
             formData.append('site', this.site);
             formData.append('ramacDevice', this.ramacDevice);
             formData.append('fullname_user', fullname_user);
             formData.append('user_action', user_action);
             formData.append('description', description);
             
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/mechines/addGet.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })

             .then((res) => {
                 console.log(res, 'Test');
                    this.machineName = '',
                    this.position = '',
                    this.type = '',
                    this.site = '',
                    this.ramac_device = '',
                    this.image =  '',
                    this.filename = ''
                    var x = document.getElementById("snackbar1");
                        x.className = "show";
                        setTimeout(function(){ 
                        x.className = x.className.replace("show", "");
                    }, 3000);
             })
         }
     },
    
     back() {
         this.$router.go(-1)
     }
   }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}

select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  #snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>