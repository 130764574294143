<template>
    <div class="addUser bg-dark">
       <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> All fields are requred!!!
        </div>
      <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong>New Status has been added!!!
        </div>
        <div class="form">
        <form class="login-form">
         <div class="form-group">
            <label for="name">Name:</label>
            <input type="name" class="form-control" placeholder="Enter name" id="name" v-model="name">
        </div>
         <div class="form-group">
            <label for="description">Description:</label>
            <input type="text" class="form-control" placeholder="Enter description" id="description" v-model="description">
        </div>
      <button type="button" class="btn-sm btn-info" @click="addStatus()">Submit</button>
      <router-link tag="button" to="/MachineStatus" class="btn-sm btn-danger">Cancel</router-link>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            name: '',
            description: '',
        }
    },

methods: {
     addStatus() {
         if(this.name == '' || this.description == '') {
            if (this.name == '') {
                document.getElementById("name").style.border = "1px solid red";
            }
            if(this.description == '') {
                document.getElementById("description").style.border = "1px solid red";
            }
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
         } else {
             let formData = new FormData();
             console.log(formData)
             //USER DETAILS
             let action = 'addStatus';
             let fullname  = localStorage.getItem('fullname')
             let description = fullname+" added new machine status."
        
             formData.append('action', action);
             formData.append('fullname', fullname)
             formData.append('description', description)
             formData.append('name', this.name);
             formData.append('description_product', this.description);
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php',
                 //url: 'http://m4abackend/mechines/machine_config/machine_config.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })
             .then(() => {
                this.name = ''
                this.description = ''
                var x = document.getElementById("snackbar1")
                x.className = "show"
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
                }, 3000);
             })
         }
     }
   }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  #snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>