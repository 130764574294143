<template>
  <div class="mechines bg-secondary">
  <h1 class="">Products</h1>
  <router-link tag="button" to="/AddProduct" class="btn-sm btn-info" id="addUser" v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Add Product</router-link>
  <table class="table table-hover table-dark">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">MachineType</th>
      <th scope="col">Type</th>
      <th scope="col">Duration</th>
      <th scope="col">Description</th>
      <th scope="col">Created</th>
      <th scope="col" v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(product, index) in products" :key="product.id">
      <td>{{ index+1 }}</td>
      <td>{{ product.name }}</td>
      <td>{{ product.machine_type }}</td>
      <td>{{ product.type }}</td>
      <td>{{ product.mnf_time }}</td>
      <td>{{ product.description }}</td>
      <td>{{ product.created_date }}</td>
      <td>
        <router-link
        v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'"
        tag="i"
        :to="{ name: 'EditProduct', params: { product }}"
        class="btn btn-info btn-sm">
        <i class="fa fa-pen"></i>
        </router-link>
        <button 
        v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'"
        @click="deleteProduct(product)"
        class="btn btn-info btn-sm">
        <i class="fa fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data () {
      return {
        products: [],
        userType: ''
      }
    },
    
beforeMount() {
  this.$store.dispatch('fetchAllProducts')
},

  // computed: {
  //   products() {
  //     return this.$store.state.products.extruderProduct
  //   }
  // },

  mounted() {
    this.allProducts()
    this.userType = localStorage.getItem('userType')
  },

  methods: {
    //SELECT ALL PRODUCTS
    allProducts() {
       axios.get('https://m4a.ramacsystems.com/backend/products/products.php')
      //axios.get('http://m4abackend/products/products.php')
        .then((res) => {
          this.products = res.data
        })
        .catch(function (error) {
            console.log(error)
        })
    },

    //DELETE USER
    deleteProduct(product) {
        var r = confirm("Are you sure you want to delete this products ?");
        if (r == true) {
        let fullname_user = localStorage.getItem('fullname') 
        let user_action = 'Delete product'
        let description = fullname_user +" adited product " + product.name  
        let id = product.id
        axios.get('https://m4a.ramacsystems.com/backend/products/product_delete.php?params', {
          params: {
            id: id,
            fullname_user: fullname_user,
            user_action: user_action,
            description: description
          },
        })
        .then(() => {
            this.allProducts()
        })
     } else {
       return
        }
    },
  },
}
</script>
<style scoped>
.mechines,
h1,
td,
th {
    color: rgb(255, 255, 255);
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addUser {
  float: right;
  margin-right: 90px;
}

.btn {
  margin: -10px 10px -10px 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5bc0de;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5bc0de;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
