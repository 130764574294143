<template>
    <div class="addUser bg-dark">
       <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> All fields are required!!!
        </div>
       <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong> User has been updated!!!
        </div>
        <div class="form">
        <form class="user-form">
         <div class="form-group">
            <label for="fullname">Fullname:</label>
            <input type="fullname" class="form-control" placeholder="Enter Fullname" id="fullname" v-model="user.fullname">
        </div>
         <div class="form-group">
            <div id="tooltip">
                <span id="tooltiptext">Email is not valid</span>
            </div>
            <label for="email">Email address:</label>
            <input type="email" class="form-control" placeholder="Enter email" id="email" v-model="user.email" @change="emailValidation(user.email)">
        </div>
        <div class="form-group">
            <label for="userType">UserType:</label>            
            <select class="form-control"  id="userType" v-model="user.userType">
                <option selected>{{ user.userType }}</option>
                <option v-for="user in userTypes" :key="user.user">{{ user }}</option>
            </select>
        </div>
         <div class="form-group">
            <label for="tel">Phone:</label>
            <input type="tel" class="form-control" placeholder="Enter Phone number" id="tel" v-model="user.tel">
        </div>
         <div class="form-group">
            <label for="tel">Telegram:</label>
            <input type="telegram" class="form-control" placeholder="Enter Telegram user name" id="tel" v-model="user.telegram">
         </div>
         <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" class="form-control" placeholder="Enter password" id="password" v-model="user.password">
        </div>
      <button type="button" class="btn-sm btn-info" @click="updateUser()">Update</button>
      <router-link tag="button" to="/Users" class="btn-sm btn-danger">Back</router-link>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        user: {
            fullname: '',
            email: '',
            userType: '',
            tel: '',
            telegram: '',
            id: '',
            password: ''
        }
    },
    data() {
        return {
            fullname: '',
            email: '',
            tel: '',
            userType: '',
            password: '',
            userTypes: [
                'Admin',
                'Manager',
            ]
        }
    },

// mounted() {
// console.log(this.user)
// },
mounted() {
    this.validateTel()
},
methods: {
    validateTel(e) {
        const input = document.getElementById('tel');
        input.addEventListener('input', function() {
            if (this.value.length > 10) {
            this.value = this.value.substring(0, 10);
            }
        });
    },

    validateEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    },

    emailValidation() {
    const email = this.email;
        if (this.validateEmail(this.email)) {
        console.log("Email is valid");
        document.getElementById("tooltip").style.visibility = 'hidden';
        document.getElementById("tooltiptext").style.visibility = 'hidden';
        } else {
            console.log("Email is not valid");
            document.getElementById("tooltip").style.visibility = 'visible';
            document.getElementById("tooltiptext").style.visibility = 'visible';
        }
    },
     updateUser() {
         if(this.user.fullname == '' || this.user.email == '' || this.user.tel == '' || this.user.userType == '' || this.user.telegram == '') {
            //ADD RED BORDER ON A REQUIRED FIELD
            if(document.getElementById('fullname').value == '') {
                document.getElementById('fullname').style.border = '2px solid red'
            }
            if(document.getElementById('email').value == '') {
                document.getElementById('email').style.border = '2px solid red'
            }
            if(document.getElementById('tel').value == '') {
                document.getElementById('tel').style.border = '2px solid red'
            }
            if(document.getElementById('userType').value == '') {
                document.getElementById('userType').style.border = '2px solid red'
            }
            if(document.getElementById('password').value == '') {
                document.getElementById('password').style.border = '2px solid red'
            }
            //END ADD RED BORDER ON A REQUIRED FIELD
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 3000);
         } else {
             let formData = new FormData();
             //AUDIT LOG PARAMS
            let fullname_user = localStorage.getItem('fullname') 
            let user_action = 'edited user'
            let description = fullname_user +" edited " + this.user.fullname
             //FORM DATA 
             formData.append('id', this.user.id)
             formData.append('fullname', this.user.fullname);
             formData.append('email', this.user.email);
             formData.append('tel', this.user.tel);
             formData.append('userType', this.user.userType);
             formData.append('password', this.user.password);
             formData.append('telegram', this.user.telegram);
             formData.append('fullname_user', fullname_user)
             formData.append('user_action', user_action)
             formData.append('description', description)
             //API REQUEST
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/editView.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })
             .then((res) => {
                 console.log(res);
                var x = document.getElementById("snackbar1");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 3000);
             this.$router.go(-1)
             })
         }
     },
   }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  .optional {
    font-style: italic;
    font-size: 12px;
  }
/* Tooltip container */
#tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
#tooltip #tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #ff9966;
  color: #ff0000;
  text-align: center;
  padding: 3px 0;
  border-radius: 6px;
  margin-left: -387px;
  margin-top: -16px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */
</style>