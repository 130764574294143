<template>
    <div class="addUser bg-dark">
       <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> All fields are required!!!
        </div>
      <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong>New user has been added!!!
        </div>
        <div class="form">
        <form class="login-form">
         <div class="form-group">
            <label for="fullname">Fullname:</label>
            <input type="fullname" class="form-control" placeholder="Enter Fullname" id="fullname" v-model="fullname">
        </div>
         <div class="form-group">
            <div id="tooltip">
                <span id="tooltiptext">Email is not valid</span>
            </div>
            <label for="email">Email address:</label>
            <input type="email" class="form-control" placeholder="Enter email" id="email" v-model="email" @change="emailValidation(email)">
        </div>
        <div class="form-group">
            <label for="userType">UserType:</label>            
            <select class="form-control"  id="userType" v-model="userType">
                <option disabled selected>Choose UserType</option>
                <option value="Admin">Admin</option>
                <option value="Manager">Manager</option>
            </select>
        </div>
         <div class="form-group">
            <label for="tel">Phone:</label>
            <input type="number" maxlength="10" class="form-control" placeholder="Enter Phone number" id="tel" v-model="tel">
        </div>
         <div class="form-group">
            <label for="tel">Telegram: <span class="optional">(optional)</span></label>
            <input type="telegram" class="form-control" placeholder="Enter Telegram user name" id="tel" v-model="telegram">
        </div>
         <div class="form-group">
            <label for="password">Password:</label>
            <input type="email" class="form-control" placeholder="Enter password" id="password" v-model="password">
        </div>
      <button type="button" class="btn-sm btn-info" @click="addUser()">Submit</button>
      <router-link tag="button" to="/Users" class="btn-sm btn-danger">Cancel</router-link>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
// import bcrypt from 'bcryptjs'
export default {
    data() {
        return {
            fullname: '',
            telegram: '',
            email: '',
            tel: '',
            userType: '',
            password: ''
        }
    },

mounted() {
    this.validateTel()
},

methods: {
    // testBcryp() {
    //     console.log(this.encryptPassword(this.password))
    // },

    // encryptPassword(password) {
    //     const salt = bcrypt.genSaltSync(10)
    //     return bcrypt.hashSync(password, salt)
    // },
    validateTel(e) {
        const input = document.getElementById('tel');
        input.addEventListener('input', function() {
            if (this.value.length > 10) {
            this.value = this.value.substring(0, 10);
            }
        });
    },

    validateEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    },
    emailValidation() {
    const email = this.email;
        if (this.validateEmail(email)) {
        document.getElementById("tooltip").style.visibility = 'hidden';
        document.getElementById("tooltiptext").style.visibility = 'hidden';
        } else {
            console.log("Email is not valid");
            document.getElementById("tooltip").style.visibility = 'visible';
            document.getElementById("tooltiptext").style.visibility = 'visible';
        }
    },
    
    //ADD NEW USER
     addUser() {
         if(this.fullname == '' || this.email == '' || this.tel == '' || this.userType == '' || this.password == '') {
            //ADD RED BORDER ON A REQUIRED FIELD
            if(document.getElementById('fullname').value == '') {
                document.getElementById('fullname').style.border = '2px solid red'
            }
            if(document.getElementById('email').value == '') {
                document.getElementById('email').style.border = '2px solid red'
            }
            if(document.getElementById('tel').value == '') {
                document.getElementById('tel').style.border = '2px solid red'
            }
            if(document.getElementById('userType').value == '') {
                document.getElementById('userType').style.border = '2px solid red'
            }
            if(document.getElementById('password').value == '') {
                document.getElementById('password').style.border = '2px solid red'
            }
            //END ADD RED BORDER ON A REQUIRED FIELD
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
         } 
         
         else {
             let formData = new FormData();
             console.log(formData)
            //AUDIT LOG PARAMS
             let fullname_user = localStorage.getItem('fullname') 
             let user_action = 'Add user'
             let description = fullname_user +" added new user " + this.fullname
             //USER DETAILS
             formData.append('fullname', this.fullname);
             formData.append('email', this.email);
             formData.append('tel', this.tel);
             formData.append('userType', this.userType);
             formData.append('password', this.password);
             formData.append('telegram', this.telegram);
             formData.append('fullname_user', fullname_user);
             formData.append('user_action', user_action);
             formData.append('description', description);
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/addUsers.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })
             .then((res) => {
                 console.log(res);
                this.fullname = '',
                this.email = '',
                this.tel = '',
                this.userType = '',
                this.password = '',
                this.userTypes = 'Choose UserType'
                var x = document.getElementById("snackbar1");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
              }, 3000);
             })
         }
     }
   }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  #snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  .optional {
    font-style: italic;
    font-size: 12px;
  }
/* Tooltip container */
#tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
#tooltip #tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #ff9966;
  color: #ff0000;
  text-align: center;
  padding: 3px 0;
  border-radius: 6px;
  margin-left: -387px;
  margin-top: -16px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */

</style>