<template>
    <div class="addMachine bg-dark">
      <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> All fields are requred!!!
        </div>
       <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong>Machine details has been updated!!!
        </div>
        <div class="form">
        <form class="login-form">
         <div class="form-group">
            <label for="name">Mechine Name:</label>
            <input type="name" class="form-control" placeholder="Enter name" id="machineName" v-model="machine.machineName">
        </div>
         <div class="form-group">
            <label for="position">Position:</label>
            <input type="position" class="form-control" placeholder="Enter position" id="position" v-model="machine.position">
        </div>
         <div class="form-group">
            <label for="position">Type:</label>
            <select class="form-control" v-model="machine.type" id="type">
                <option disabled selected>Choose Type</option>
                <option v-for="type in machinesType" :key="type.type">{{ type }}</option>
            </select>
        </div>
         <div class="form-group">
            <label for="position">Site:</label>            
            <select class="form-control" v-model="machine.site" id="site">
                <option disabled selected>Choose Site</option>
                <option v-for="site in sites" :key="site.site">{{ site }}</option>
            </select>
        </div>
         <div class="form-group">
            <label for="ramacDevice">RAMAC Device:</label>
              <input type="text" class="form-control" disabled placeholder="Enter ramacDevice ID" id="ramacDevice" v-model="machine.ramacDevice">
        </div>
      <button type="button" class="btn-sm btn-info" @click="addMechine">Submit</button>
      <router-link to="/Injection" tag="button" class="btn-sm btn-danger">Back</router-link>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        machine: {
            machineName: '',
            position: '',
            site: '',
            ramacDevice: '',
            machinesType: '',
            pulseConfig: ''
        }
    },

    data() {
        return {
            machineName: '',
            position: '',
            type: '',
            site: '',
            ramacDevice: '',
            sites: [
               'Site A',
               'Site B'
            ],
            machinesType: [
                'Compression Mould',
                'Injection Mould',
                'Extruder'
            ],
        }
    },
methods: {
     addMechine() {
         if(this.machine.machineName == '' || this.machine.position == '' || this.machine.type == '' || this.machine.site == '' || this.machine.ramacDevice == '') {
            //VALIDATION FIELDS ARE EMPTY
            if (this.machine.machineName == '') {
                document.getElementById("machineName").style.border = "1px solid red";
            }
            if (this.machine.position == '') {
                document.getElementById("position").style.border = "1px solid red";
            }
            if (this.machine.type == '') {
                document.getElementById("type").style.border = "1px solid red";
            }
            if (this.machine.site == '') {
                document.getElementById("site").style.border = "1px solid red";
            }
            if (this.machine.ramacDevice == '') {
                document.getElementById("ramacDevice").style.border = "1px solid red";
            }
            //VALIDATION FIELDS ARE EMPTY
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 3000);
        } else {
             let formData = new FormData();
            //AUDIT LOG PARAMS
             let fullname_user = localStorage.getItem('fullname') 
             let user_action = 'Edited Machine'
             let description = fullname_user +" edited machine " + this.machine.machineName

             formData.append('machineName', this.machine.machineName);
             formData.append('position', this.machine.position);
             formData.append('type', this.machine.type);
             formData.append('site', this.machine.site);
             formData.append('ramacDevice', this.machine.ramacDevice);
             formData.append('id', this.machine.id);
             formData.append('fullname_user', fullname_user);
             formData.append('user_action', user_action);
             formData.append('description', description);
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/mechines/editMachine.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })

             .then((res) => {
                console.log(res)
                var x = document.getElementById("snackbar1");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 3000);
            })
         }
     },
   }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}

select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>