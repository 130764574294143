<template>
    <div class="addProduct  bg-dark">
         <div class="alert" role="alert" id="snackbar">
            <strong>Error:</strong> All fields are requred!!!
        </div>
         <div class="alert" role="alert" id="snackbar1">
            <strong>Sucess:</strong>New product added.
        </div>
         <div class="alert" role="alert" id="snackbar2">
            <strong>Error:</strong>Product already exist.
        </div>
        <form class="add-form">
         <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" class="form-control" placeholder="Enter name" id="name" v-model="product.name">
        </div>
        <div class="form-group">
            <label for="machine_type">Machine Type:</label>
            <select class="form-control" v-model="product.machine_type" id="machine_type">
                 <option disabled selected value=""> {{ product.machine_type }}</option>
                 <option v-for="types in machines" :key="types.types">{{ types }}</option>
            </select>
        </div>

         <div class="form-group">
            <label for="type">Type:</label>
            <input type="text" class="form-control" placeholder="Enter type" id="type" v-model="product.type">
        </div>
         <div class="form-group">
            <label for="mnf_time">MNF Time:</label>
            <input type="number" class="form-control" placeholder="Enter MNF Time" id="mnf_time" v-model="product.mnf_time">
        </div>
         <div class="form-group">
            <label for="description">Description:</label>
            <input type="text" class="form-control" placeholder="Enter description" id="description" v-model="product.description">
        </div>
      <button type="button" class="btn-sm btn-info" @click="editProduct(product.id)">Submit</button>
      <router-link tag="button" to="/Products" class="btn-sm btn-danger">Cancel</router-link>
    </form>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        product: {
            id: '',
            name: '',
            machine_type: '',
            typ: '',
            mnf_time: '',
            description: ''
        }
    },

    data() {
        return {
         function_edit: 'editProduct',
         machines: [
                'Compression Mould',
                'Injection Mould',
                'Extruder',
                'Corrugator'
            ],
        }
    },

    methods: {
        //ADD PRODUCTS 
        editProduct(id) {
            //VALIDATE FORM INPUTS
            if(this.product.name == '' || this.product.type == '' || this.product.mnf_time == '' || this.product.description == '' || this.product.machine_type == 'Choose machine type') {
                //VALIDATE IF FIELDS ARE EMPTY
                if(this.product.name == '') {
                    document.getElementById("name").style.borderColor = "red";
                }
                if(this.product.machine_type == 'Choose machine type') {
                    document.getElementById("machine_type").style.borderColor = "red";
                }
                if(this.product.type == '') {
                    document.getElementById("type").style.borderColor = "red";
                }
                if(this.product.mnf_time == '') {
                    document.getElementById("mnf_time").style.borderColor = "red";
                }
                if(this.product.description == '') {
                    document.getElementById("description").style.borderColor = "red";
                }
                //VALIDATE IF FIELDS ARE EMPTY
               var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
            }else {
            let formData = new FormData
            console.log(formData)
            //AUDIT LOG PARAMS
             let fullname_user = localStorage.getItem('fullname') 
             let user_action = 'Edit product'
             let l_description = fullname_user +" adited product " + this.product.name

            formData.append('id', id)
            formData.append('name', this.product.name)
            formData.append('machine_type', this.product.machine_type)
            formData.append('type', this.product.type)
            formData.append('mnf_time', this.product.mnf_time)
            formData.append('description', this.product.description)
            formData.append('function', this.function_edit)
            formData.append('fullname_user', fullname_user);
            formData.append('user_action', user_action);
            formData.append('l_description', l_description);
            axios({
                url: 'https://m4a.ramacsystems.com/backend/products/products.php',
               // url: 'http://m4abackend/products/products.php',
                method: 'post',
                data: formData,
                config: { headers: {'Content-Type': 'multipart/formdata'}}
            })
            .then((res) => {
                console.log(res)
                //PRODUCT ADDED
                var x = document.getElementById("snackbar1");
                    x.className = "show";
                    setTimeout(function(){ 
                    x.className = x.className.replace("show", "");
                }, 2000);

                //CLEAR FORM
                this.name = '',
                this.type = '',
                this.mnf_time = '',
                this.description = ''
            })
        .catch(function (error) {
            console.log(error)
           })
        }
      }
    }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #14eb00 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar2 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar2.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>